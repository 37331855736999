// Graph Query Service

import { gql } from "@apollo/client";


//MasterData Query
export const MASTERDATA_QUERY = gql`query MasterData{
    lOLMasterData{
        headerLogo{
            path
        }
        bannerImage{
            path
        }
        bannerText
        footerLeft
        footerCenter
        footerRight
    }
  }`;

//MyLinks Query
export const MYLINKS_QUERY = gql`query MyLinks($roleNames: [String!]){
                                    lOLMyLinksCollection(where:  {
                                        publish:  {
                                            eq: true
                                        }
                                    }){
                                        items{
                                        title
                                        url
                                        roles(where:  {
                                            roleName:  {
                                                in: $roleNames
                                            }
                                        }){
                                            items{
                                            roleName
                                            }
                                        }
                                        publish
                                        }
                                    }
                                    }`;

//Brand Query
export const BRAND_QUERY1 = gql`query Brands($roleNames: [String!]){
                                lOLBrandsCollection(where:  {
                                        publish:  {
                                            eq: true
                                        }
                                    }){
                                    items{
                                    title
                                    logo{
                                        fileName
                                        fileSize
                                        fileType
                                        path
                                    }
                                    primaryColor
                                    secondaryColor
                                    heroImage{
                                        fileName
                                        fileSize
                                        fileType
                                        path
                                    }
                                    roles(where:  {
                                            roleName:  {
                                                in: $roleNames
                                            }
                                        }){
                                        items{
                                        roleName
                                        }
                                    }
                                    publish
                                    }
                                }
                                }`;

//Brand Query
export const BRAND_QUERY = gql`query Brands($roleNames: [String!]){
    lOLBrandsCollection(where:  {
            publish:  {
                eq: true
            }
        }){
        items{
        title
        logo{
            fileName
            path
        }
        headerLogo{
        fileName
        path
      }
        primaryColor
        secondaryColor
        heroImage{
            fileName
            path
        }
        nodeAlias
        roles(where:  {
                roleName:  {
                    in: $roleNames
                }
            }){
            items{
            roleName
            }
        }
        publish
        }
    }
    }`;

export const BRAND_FOLDER_QUERY = gql`query FoldersQuery($parentBrand: String){
        lOLFoldersCollection(where:  {
           active:  {
              eq: true
           }
           parentBrand:  {
              eq: $parentBrand
           }
        }){
          items{
            folderIcon
            folderName
            parentBrand
            active
          }
        }
      }`;
export const BRAND_FOLDER_DOCUMENT_QUERY = gql`query DocumentsQuery($parentBrand: String, $roleNames: [String!]){
  lOLDocumentsCollection(where:  {
     parentBrand:  {
        eq: $parentBrand
     }
    publish:  {
        eq: true
     }
  }){
    items
    {
     title
     fileAttachment{
      path
     }
     folderName{
      items{
        folderName
      }
     }
     roles(where:  {
        roleName:  {
           in: $roleNames
        }
     }){
      items{
        roleName
      }
     }
     parentBrand
     publish
    }
  }
}`;
