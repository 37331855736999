import React from 'react';
import './Loader.scss';
import { CircularProgress } from '@mui/material';



// export interface IBanner {
//   image:string;
//   imageText:string;
// }
function Loader() {
  return (
    <div className="loader-container" data-testid="loaderTestId">
      <CircularProgress />
    </div>
  );
};
export default Loader;