import React from 'react'
import './Footer.scss'
import parse from 'html-react-parser';
import { useAppContext } from '../../context/AppContext';
 

function Footer() {
    const { masterData } = useAppContext();
    return (
            <footer className="footer">
                <div className="footer-content">
                    <div className="column left-column">
                        {parse(masterData?.lOLMasterData?.footerLeft || '')}    
                    </div>
                    <div className="column centre-column">
                        {parse(masterData?.lOLMasterData?.footerCenter || '')}
                    </div>
                    <div className="column right-column">
                        {parse(masterData?.lOLMasterData?.footerRight || '')}     
                    </div>
                </div>
                <div className="divider"></div>
                <div className="copyright">
                    &copy; 2024 Forage Genetics International, LLC
                </div>
                </footer>
        );
};
export default Footer;
